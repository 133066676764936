import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Carousel from './carousel';

// These are some global styles
// the make the page pretty.

const Page = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;


export default function CarouselWrap() {
    return (
        <Page>
            <Carousel initialHeight={"auto"} initialWidth={800} nav={false}>
                <span value=""></span>
                <span value=""></span>
                <span value="İyi bir mini kulübe sahip olmak artık bir çok tesis için birincil tercih edilme nedeni haline gelmiş durumda..."></span>
            </Carousel>
        </Page>
    );
}