import React, { useState } from 'react';
import styled from 'styled-components';
import Karen from '../../img/karenresim.jpeg'

const FounderWrapper = styled.section`
    width:100%;
    float:left;
    padding:80px 120px;
    box-sizing:border-box;
    @media only screen and (max-width:1000px) {
        padding:40px 10px;
    }
`;
const FounderLeft = styled.div`
    width:50%;
    float:left;
    padding-right:50px;
    box-sizing:border-box;
`
const FounderRight = styled.div`
    width:50%;
    float:left;
    button{
        border-radius:40px;
        border-style:none;
        color:white;
        font-weight:bold;
        background-color:#FFB401;
        padding:20px 30px;
        transition:300ms;
        outline:none;
        border:2px solid #FFB401;
        &:hover{
            transition:300ms;
            background:white;
            color:#FFB401;
            border:2px solid #FFB401;
        }
    }
    p{
        transition:300ms;
    }
`
const FounderImg = styled.img`
    width:334px;
    height:333px;
    float:right;
    object-fit:cover;
   border-radius:71% 29% 37% 60% / 60% 48% 53% 40%;
   object-position:top;
`
export default function Founder(){
    const [acil,setAcil] = useState(true);
    function acillir (){
        setAcil(!acil);
    }
    return <FounderWrapper>
        <FounderLeft>
            <FounderImg src={Karen}/>
        </FounderLeft>
        <FounderRight>
            <h1>Kurucumuz Karen Ak kimdir?</h1>    
            <p>“Kidsonly by k®” ve “Kids' Computer by k®” markalarının yaratıcısı ve kurucusu Karen Ak doğup büyüdüğü İstanbul’da Alman Lisesi’ni bitirdikten sonra Almanya’ya giderek yüksek öğrenimini tekstil dalında tamamladı. Alman{acil?" ekolünün kazandırdığı disiplin daha sonra iş hayatındaki başarısının temel taşını oluşturdu. Uzun yıllar bir Alman şirketinin İstanbul temsilciliğinde yöneticilik yaptıktan sonra 1993 yılında Antalya’ya yerleşti ve kızının doğumu ile çocuklar konusunda çalışmaya gönül verdi. Futurekids® ile baslayan çalışmalarına paralel, çocuklar için daha ilginç ve yaratıcı konseptler geliştirmek isteği ile kapsamlı bir hizmet alanına yöneldi. Bunun sonucunda kendi markalarını yarattı. ":"..."} </p>
            {/* <button onClick={acillir}>DAHA FAZLA GÖSTER</button> */}
        </FounderRight> 
    </FounderWrapper>
}