import React, { Fragment } from 'react';
import styled from 'styled-components';
import R from '../../r.png'

const FooterWrapper = styled.section`
width:100%;
float:left;
padding:80px 120px;
background-color:#ff9229;
box-sizing:border-box;
@media only screen and (max-width:1000px) {
        padding: 20px 10px;
    }
`
const LogoWrapper = styled.div`
width:100%;
height:auto;
float:left;
img{
    width:100px;
    margin:0px auto;
    display:block;
}
`;
const LogoYaziTasiyici = styled.div`
width:100%;
height:auto;
float:left;
`;
const LogoText = styled.span`
    width:300px;
    line-height:20px;
    padding-left:80px;
    padding-top:10px;
    margin:0px auto;
    font-family:"Desyrel";
    font-size:1.7em;
    color:#312783;
    letter-spacing:2px;
    display:block;
    
    @media only screen and (max-width:1000px) {
        width:100%;
            margin-left:0px;
            padding-left:0px;
            box-sizing:border-box;
            text-align:center;
    }
    img{
        width:20px;
    }
    span{
        letter-spacing:0px;
        margin-left:55px;
        font-size:.6em;
        @media only screen and (max-width:1000px) {
            margin-left:0px;
    }
    }
`;
const Marker = styled.mark`
background:none;
color:#E30613;`
const FooterBottom = styled.div`
    width:100%;
    float:left;
    padding: 20px 120px;
    text-align:center;
    color:white;
    box-sizing:border-box;
    background-color:#71BC50;
    @media only screen and (max-width:1000px) {
        padding: 20px 10px;
    }
`
export default function Footer() {
    return <Fragment>
        <FooterWrapper>
            <LogoWrapper>
                <img src="https://kidsonlybyk.com/image/logoNB.png" />
            </LogoWrapper>
            <LogoYaziTasiyici>
                <LogoText>
                    <Marker>kids</Marker>only by k<img src={R} />
                    <br></br><span>mini club organisation</span>
                </LogoText>
            </LogoYaziTasiyici>

        </FooterWrapper>
        <FooterBottom>
            Kidsonly By K® 2020 | All Rights Reserved
        </FooterBottom>
    </Fragment>
}