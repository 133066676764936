import React, { Fragment } from 'react';
import TopV2 from '../../layouts/topV2'
import CenterV3 from '../../layouts/centerV3'
import Bottom from '../../layouts/bottom'
import styled from 'styled-components'

const IkWrap = styled.section`
width:100%;
padding:80px 120px;
box-sizing:border-box;
float:left;
p{
    font-size:32px;
}
`
function HumanResource() {

    return <Fragment>
        <TopV2 name='İnsan Kaynakları'/>
        {/* <CenterV3 /> */}
        <IkWrap>
        <p>Başvurularınız için:</p>
           Cv'nizi, kendinizi kısaca anlatan bir yazı ile karen@kidsonlybyk.com adresine iletebilirsiniz. İncelememiz tamamlandığında, size en kısa süre içerisinde geri dönüş sağlıyor olacağız.
        </IkWrap>
        <Bottom />
    </Fragment>
}


export default {
    routeProps: {
        path: '/human-resource',
        component: HumanResource
    },
    name: 'İnsan Kaynakları',
    headerLocale: true
    //icon:<IoMdAnalytics />
}