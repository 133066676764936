import React from 'react';
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { Link } from "react-router-dom";
const InfoBarWrapper = styled.section`
    width:100%;
    float:left;
    padding:20px 120px;
    box-sizing:border-box;
    background-color:#FFB401;
    @media only screen and (max-width:1000px) {
        padding:20px 10px;
    }
`;
const PageName = styled.h1`
color:white;
font-weight:bold;
float:left;
`;
const LocationWrap = styled.div`
 float:right;
 color:white;
 margin-top:20px;
 a{
     padding:5px 10px;
     background-color:white;
     color:black;
     border-radius:5px;
     text-decoration:none;
     float:left;
 }
 b{
     float:left;
     margin:5px;
 }
 span{
    padding:5px 10px;
     background-color:#FEC131;
     color:white;
     border-radius:5px;
     text-decoration:none;
     float:left;
 }
`
export default function InfoBar(props) {
    let location = useLocation();
    console.log(props)
    return <InfoBarWrapper>
        <PageName>{props.name}</PageName>
        <LocationWrap>
            <Link to="/">Ana Sayfa</Link> <b>></b> <span>{props.name}</span>
        </LocationWrap>
    </InfoBarWrapper>
}