import React from 'react';

import modules from './modules';
import { Switch, Route } from "react-router-dom";


export default function Routes() {
    //const [apiUrl] = useState("https://api.unimed.com.tr/")
   
    return (
        <Switch>
            {modules.map(module => (
                <Route
                    {...module.routeProps}
                    pageName={module.name}
                    key={module.name}
                    exact={module.routeProps.exact}
                    component={module.routeProps.component}
                />

            ))}
        </Switch>
    )
}