import React,{useState} from 'react';
import { Link, useLocation } from "react-router-dom";
import modules from '../../modules';
import styled from 'styled-components'
import Logo from '../logo';
import NavbarMenuButtton from './navbarMenuButton'
// import Logo from '../Logo'
import Instagram from '../../img/instagram.png'
const HeaderWrapper = styled.header`
  width:100%;
  height:auto;
  padding:${props => props.pathName === "/" ? "0px 120px 0px 120px" : "0px 0px"};
  /* font-family:'Open Sans'; */
  /* font-family: 'Teko', sans-serif; */
  font-family: 'Saira Extra Condensed', sans-serif;
  /* background:white; */
  float:left;
  box-sizing:border-box;
  z-index:10;
  position:relative;
  @media (max-width: 1000px) {
      padding:0px;
    }
  header{
    width:100%;
    float:left;
    background:white;
    padding:20px;
    box-sizing:border-box;
    ul{
      float:right;
      margin:0px;
      margin-top:30px;
      padding:0px;
      @media (max-width: 1000px) {
        display:none;
      }
      li{
        float:left;
        padding:15px 20px;
        box-sizing:border-box;
        text-decoration:none;
        list-style:none;
        border-radius:5px;
        transition:300ms;
        &:hover{
          background-color:#EF5D50;
          transition:300ms;
          a{
            color:white;
          }
          
        }
        &:last-child{
          padding:12px;
        }
        a{
          text-decoration:none;
          color:#2D2D2D;
          font-weight:600;
          text-transform:uppercase;
          font-size:1em;
          letter-spacing: 0.5px;
          img{
            width:24px;
          }
        }
      }
    }
}
`;
const Modal = styled.div`
 /*display: none;*/
  position: fixed;
  z-index: 100;
  padding: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: black;
  box-sizing:border-box;
  /* -webkit-backface-visibility: hidden; */
`
const ModelContent = styled.div`
  box-sizing:border-box;
  padding: 0;
  ul{
    width:100%;
    margin:0px;
    padding:0px;
    li{
      width:100%;
      text-align:center; 
      padding:20px 0px;
      box-sizing:border-box;
      a{
        text-decoration:none;
        color:white;
        cursor:pointer;
      }
    }
  }
`
const Close = styled.span`
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
    &:hover,
    &:focus {
        color: #999;
        text-decoration: none;
        cursor: pointer;
}
`
export default function Header(props) {
  const [modal, setModal] = useState(false);
  let location = useLocation();
  console.log(location)
  return (
    <HeaderWrapper pathName={location.pathname}>
      <header>
        <Logo />
        <ul>
          {modules.map(module => (
            module.headerLocale ? <li key={module.name}>
              <Link to={module.routeProps.path}>{module.name}</Link>
            </li> : false
          ))}
          <li><a href="https://www.instagram.com/kidsonlybyk/?hl=tr" target="_blank"><img src={Instagram}/></a></li>
        </ul>
        <NavbarMenuButtton tikla={()=>setModal(true)}/>
      </header>
      {modal ? <Modal>
            <Close onClick={()=>setModal(false)}>&times;</Close>
            <ModelContent>
            <ul>
          {modules.map(module => (
            module.headerLocale ? <li key={module.name}>
              <Link to={module.routeProps.path}>{module.name}</Link>
            </li> : false
          ))}
          <li><a href="https://www.instagram.com/kidsonlybyk/?hl=tr" target="_blank"><img src={Instagram}/></a></li>
        </ul>
            </ModelContent>
        </Modal> : false}
    </HeaderWrapper>

  )
}