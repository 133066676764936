import React, { Fragment } from 'react';
import Top from '../../layouts/top';
import Center from '../../layouts/center';
import Bottom from '../../layouts/bottom';
function Home() {

    return <Fragment><Top /><Center/><Bottom/></Fragment>
}


export default {
    routeProps: {
        path: '/',
        exact:true,
        component: Home
    },
    name: 'Ana Sayfa',
    headerLocale: true
    //icon:<IoMdAnalytics />
}