import React, { Fragment } from 'react';
import TopV2 from '../../layouts/topV2'
import CenterV3 from '../../layouts/centerV3'
import Bottom from '../../layouts/bottom'
import styled from 'styled-components'

const IkWrap = styled.section`
width:100%;
padding:80px 120px;
box-sizing:border-box;
float:left;
p{
    font-size:32px;
}
h4{
    font-weight:400;
    font-style:italic;
}
`
function Referanslar() {

    return <Fragment>
        <TopV2 name='Referanslarımız'/>
        {/* <CenterV3 /> */}
        <IkWrap>
        <p>Hizmet Verdiğimiz Kuruluşlar</p>
            <ul>
                <li>AKKA ANTEDON HOTEL</li>
                <li>AKKA ALINDA HOTEL</li>
                <li>KEMER HOLIDAY CLUB</li>
                <li>TUI BLUE COLLECTION BELEK HOTEL</li>
                <li>TUI BLUE COLLECTION KİRİŞ HOTEL</li>
                <li>COCOS THE CLUB BODRUM</li>
            </ul>
            <h4>Birlikte çalışma fırsatı bulduğumuz tüm kuruluşlara teşekkür ederiz.</h4>
        </IkWrap>
        <Bottom />
    </Fragment>
}


export default {
    routeProps: {
        path: '/references',
        component: Referanslar
    },
    name: 'Referanslarımız',
    headerLocale: true
    //icon:<IoMdAnalytics />
}