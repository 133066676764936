import React from 'react';
import styled from 'styled-components'

const Modal = styled.div`
position:fixed;
top:0px;
left:0px;
width:100%;
height:100%;
background-color:black;
display:flex;
video{
  margin:50px auto;
}
`
const CloseButton = styled.span`
color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
    &:hover,
    &:focus {
        color: #999;
        text-decoration: none;
        cursor: pointer;
}
`

export default function ModalComponent({ handleClose, show, children }) {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <Modal className={showHideClassName}>

      {children}
      <CloseButton onClick={handleClose}>&times;</CloseButton>
    </Modal>
  );
};