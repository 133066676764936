import React from 'react';
import styled from 'styled-components'
import { FiMenu } from "react-icons/fi";
const NBMB = styled.div`
float:right;
width:${props=>props.width}px;
height:${props=>props.height}px;
margin-top:5px;
svg{
  width:100%;
  height:100%;
}
@media (min-width: 1000px) {
    display:none;
  }
`

export default function NavbarMenuButton(props){
    return <NBMB onClick={()=>props.tikla()} width={30} height={30}><FiMenu/></NBMB>
}