import React, { useEffect} from 'react';
import { BrowserRouter as Router } from "react-router-dom";

import Routes from './routes';


import PTFPN from './utils/pageTitleForProjectName';


function App() {
  useEffect(() => { PTFPN(); }); //sayfa başlığını package.json daki proje ismi sürüm adı ve version değerleri ile değiştiriyor.
  return (
    <Router>
      <Routes />
    </Router>

  );
}

export default App;
