import React, { useState } from 'react';
import styled from 'styled-components'

const TapPanelWrapper = styled.section`
width:100%;
float:left;
padding:80px 120px;
font-family:'Open Sans';
box-sizing:border-box;
`;
const Tab = styled.div`
    float: left;
    width: 25%;
    height: 300px;
    box-sizing:border-box;
    button{
        /* font-family:'Open Sans'; */
        font-family: 'Teko', sans-serif;
        /* font-family: 'Saira Extra Condensed', sans-serif; */
        font-size:18px;
        width:100%;
        float:left;
        text-align:left;
        border:none;
        padding:20px 10px;
        box-sizing:border-box;
        background-color:#71BC50;
        margin-bottom:5px;
        border-radius:5px;
        color:white;
        outline:none;
        &:hover{
            background-color:#EF5D50;
        }
    }
`
const TabContent = styled.div`
    display:none;
    float: left;
    padding: 0px 50px;
    width: 70%;
    height: auto;
    box-sizing:border-box;
`

const TabData = [
    {
        name:"Kuruluş",
        data:`1996 yılında “Futurekids®” Antalya olarak Karen Ak tarafından kuruldu.
        1997 otellerde “Futurekids®” yaz kampı faaliyetlerine  başladı.
        2001 yılında “Kids’ Computer by k®” adını aldı – hizmet alanını genişleterek “Kidsonly by k®” adı altında mini kulüp organizasyon faaliyetlerine de başladı. 
        Halen her iki alanda büyüyerek hizmet vermektedir.
        `
    },
    {
        name:"Neden biz?",
        data:<ul>
            <li>Anne ve babalar için tatillerde öncelikli konulardan biri minik misafirler için sunulan özenli aktivite seçenekleri olmaktadır. </li>
            <li>Minik misafirlerin ağırlanması konusu, günümüzde uzmanları tarafından ele alınmalı, otel yönetiminin sorumluluğunda olmamalıdır. </li>
            <li>Sektörün yaratıcısı ve bu konumda uzmanlaşmış tek kurumsal yapı olma özelliğine sahiptir. </li>
            <li>Miniklerin eğitimine yönelik uluslararası tüm gelişmeleri takip eder ve trendlere paralel içeriklerini yeniler. </li>
            <li>Görevli personelin eğitimine maksimum önem verir. </li>
        </ul>
    },
    {
        name:"Kidsonly by k®",
        data:`Bir çocuk yuvasının aksine, mini kulüplere özel olarak tasarlanmış zengin içerikli programları ile çocukların çok eğlenceli, maceralarla dolu yeni fikirler ve arkadaşlarla tanışmalarını sağlayan güzel bir tatil deneyimidir.  Kidsonly by k® işletmesinde olan otellerde, herkes tatilin tadını çıkarır. Miniklerin son derece istekli olarak katıldıkları programlar, yetişkin otel misafirlerine de gönüllerince  tesisin onlara sunduğu olanakların tadını çıkarma fırsatı sunmaktadır. Minik misafirler ise eğitimli uzmanların gözetiminde oyunlar oynayarak, onlar için hazırlanan şovları izleyerek hayatlarının en eğlenceli zamanlarını geçrimektedirler. 

        Çocuklar ve ebeveynler için “Kidsonly by k®”, isim ve sembol olmanın ötesinde bir kavram çağrıştırmaktadır. Tatil dönüşü logoya baktıklarında, yaşadıkları muhteşem deneyimi hatırlayan minikler, programlar sırasında geliştirdikleri becerileri gündelik yaşamlarına aktarabilmektedirler. Logonun bir marka işareti haline getirilmesi bizim için önemlidir. 
        `
    },
    {
        name:"Kids’ Computer by k®",
        data:"Kids’ Computer by k®’in amacı bilgisayar ortamında küçük misafirlerin yaratıcılıklarını destekleyerek, büyülü bir yolculuğa çıkarmaktır. Bulundukları tatil ortamında onları, eğlenceli ve öğretici bir aktivite ile mutlu etmek, böylelikle ziyaret edilen tesis deneyimini anne ve babalar  için de vazgeçilmez kılmaktır."
    },
    
]
export default function TabPanel() {
    const [tabIndex,setTabIndex] = useState(0);

    return <TapPanelWrapper>
        <Tab>
            {TabData.map((x,i)=>{
                return <button onClick={()=>setTabIndex(i)} id="defaultOpen">{x.name}</button>
            })}
        </Tab>
        {TabData.map((x,i)=>{
                if(tabIndex === i ){
                    return <TabContent style={{display:"block"}} id={i}>
                <h3>{x.name}</h3>
                <p>{x.data}</p>
            </TabContent>
                }else{
                    return false
                }
                
            })}
       
    </TapPanelWrapper>
}

