import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Carousel from './carousel';
import Aksam from '../../../img/news/aksam.jpg'
import Hurriyet from '../../../img/news/hurriyet.jpg'
import Sabah from '../../../img/news/sabah.jpg'


const Page = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const Alan =styled.div`
width:100%;
`
const Kapsayici = styled.div`
width:50% !important;
padding:20px;
float:left;
box-sizing:border-box;
img{
    width:50%;
    border-radius:20px;
    height:200px;
    float:left; 
    object-fit:cover;
    object-position:top;
}
span{
    float:left;
    margin:0px 10px;
    display:block;
}
`
export default function CarouselWrap() {
    return (
        <Page>
            <Carousel initialHeight={"auto"} initialWidth={1000}>
                <Alan>
                   <Kapsayici><img src={Aksam}/><span>Aksam Gazetesi</span></Kapsayici> 
                   <Kapsayici><img src={Sabah}/><span>Sabah Gazetesi</span></Kapsayici>
                </Alan>
                <Alan>
                   <Kapsayici><img src={Hurriyet}/><span>Hürriyet Gazetesi</span></Kapsayici> 
                  
                </Alan>
                {/* <div img={Sabah}><img src={Sabah}/>Sabah Gazatesi</div>
                <div img={Hurriyet}><img src={Hurriyet}/>Hurriyet Gazatesi</div> */}
                {/* <span value="derear"></span>
                <span value="erea"></span>
                <span value="İyi bir mini kulübe sahip olmak artık bir çok tesis için birincil tercih edilme nedeni haline gelmiş durumda.."></span> */}
            </Carousel>
        </Page>
    );
}