import React, { Fragment } from 'react'
import styled from 'styled-components'

import R from '../../r.png'

const LogoWrap = styled.img`
    height:90px;
    float:left;
    object-fit:contain;
    @media only screen and (max-width:1000px) {
        height:35px;
    }
`;
const LogoText = styled.span`
    line-height:20px;
    padding-left:0px;
    padding-top:68px;
    float:left;
    font-family:"Desyrel";
    font-size:2em;
    color:#312783;
    letter-spacing:2px;
    left: -23px;
    position: relative;
    
    img{
        width:20px;
        position: relative;
        top: -6px;
    }
    span{
        letter-spacing:0px;
        margin-left:60px;
        font-size:.6em;
    }
    @media only screen and (max-width:1000px) {
        padding-top:0px;
        font-size:1em;
        left: 0px;
        line-height: 15px;
        padding-top:10px;
        img{
            width:10px;
            top: -6px;
        }
        span{
        margin-left:30px;
        font-size:.6em;
    }
    }
`;
const Marker = styled.mark`
background:none;
color:#E30613;
`
export default function Logo() {
    return <Fragment>
        <LogoWrap src="https://kidsonlybyk.com/image/logo.jpg" />
        <LogoText>
            <Marker>kids</Marker>only by k<img src={R} />
            <br></br><span>mini club organisation</span>
        </LogoText>
    </Fragment>
}