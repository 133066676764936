import React from 'react';
import Header from "../../components/header"
import Slider from "../../components/slider"
import styled from 'styled-components'

const TopWrapper = styled.section`
width:100%;
height:100vh;
float:left;
`;

export default function Top(){
    return <TopWrapper>
        <Header/>
        <Slider/>
    </TopWrapper>
}