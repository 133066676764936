import React, { useState } from 'react';
import styled from 'styled-components'
import Reload from '../../img/reload.png'

const MailWrap = styled.section`
width:100%;
float:left;
padding:80px 120px;
box-sizing:border-box;
h1{
    text-align:center;
}
`;
const MailInputs = styled.div`
width:50%;
margin:0px auto;
button{
    padding:10px;
    width:50%;
    background-color:#71BC50;
    color:white;
    border:none;
    border-radius:5px;
    float:left;
}
`
const InputStyle = styled.div`
width:100%;
float:left;
margin-bottom:20px;
span{
    margin:10px;
    width:100%;
    float:left;
    font-weight:semi-bold;
}
input,textarea{
    width:100%;
    float:left;
    background-color:white;
    border:1px solid #eeeeed;
    padding:10px 20px;
    box-sizing:border-box;
    border-radius:5px;
    margin-bottom:10px;
    outline:none;
    &:focus{
        background-color:#eeeeed
    }
}
`

const Verify = styled.div`
    float:left;
    width:50%;
    padding:0px 10px;
    box-sizing:border-box;
    margin-bottom:10px;
    input{
        width:80%;
        float:left;
        border-radius:5px;
        border: none;
        padding:10px 20px;
        box-sizing:border-box;
        letter-spacing:10px;
        text-align:center;
        text-indent:10px;
        font-weight:bold;
    }
    span{
        margin:10px;
        width:100%;
        float:left;
        font-weight:semi-bold;
    }
    img{
        width:24px;
        float:left;
        margin-left:10px;
        margin-top:4px;
        transition:300ms;
        &:hover{
            transform:rotate(180deg);
            transition:300ms;
        }
    }
`
const VerifyInp = styled.div`
    float:left;
    width:50%;
    padding:0px 10px;
    box-sizing:border-box;
    margin-bottom:10px;
    input{
        width:100%;
        float:left;
        border-radius:5px;
        border:1px solid #eeeeed;
        padding:10px 20px;
        box-sizing:border-box;
        letter-spacing:10px;
        text-align:center;
        text-indent:10px;
        font-weight:bold;
    }
    span{
        margin:10px;
        width:100%;
        float:left;
        font-weight:semi-bold;
    }
`
export default function MailPanel() {
    function makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    const [verify,setVerify] = useState(makeid(5))
    const [verifyInput, setVerifyInput] = useState("");
    const [formData, setFormData] = useState({
        fullname: "",
        phone: "",
        email: "",
        aciklama: ""
    });
    const [hata, setHata] = useState(null);
    const updateFormData = event =>
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });


    function _handleInput(value) {
        setVerifyInput(value)
    }
    function handleSubmit(event){
        event.preventDefault();
        console.log("Send")
        if(verify === verifyInput){
            console.log("Dogru")
            setVerify(makeid(5))
            setHata(false);
            setVerifyInput("")
            setFormData({
                fullname: "",
                phone: "",
                email: "",
                aciklama: ""
            });
        }else{
            console.log("Yanlış")
            setVerify(makeid(5))
            setHata(true);
            setVerifyInput("")
        }
    }
    const Hata = styled.div`
        width:45%;
        margin-left:10px;
        padding:10px;
        box-sizing:border-box;
        background:${props=>props.color};
        color:white;
        float:left;
        border-radius:5px;
    `
    
    const { fullname, phone, email, aciklama } = formData;
    return <MailWrap>
        <h1>Sizin için ne yapabiliriz?</h1>
        <MailInputs>
            <form onSubmit={handleSubmit}>
            <InputStyle>
                <span>İsim Soyisim</span>
                <input placeholder="Adınızın tamamını giriniz." name="fullname" required value={fullname} onChange={e => updateFormData(e)} type="textbox" />
            </InputStyle>
            <InputStyle>
                <span>Telefon</span>
                <input placeholder="555 123 45 78" pattern="[0-9]{3} [0-9]{3} [0-9]{2} [0-9]{2}" name="phone" required value={phone} onChange={e => updateFormData(e)} type="tel" />
            </InputStyle>
            <InputStyle>
                <span>E-Mail</span>
                <input placeholder="hello@gmail.com" name="email" required value={email} onChange={e => updateFormData(e)} type="email" />
            </InputStyle>
            <InputStyle>
                <span>Açıklama</span>
                <textarea placeholder="..." name="aciklama" required value={aciklama} onChange={e => updateFormData(e)} />
            </InputStyle>
            <Verify>
                <span>Doğrulama Kodu</span>
                <input value={verify} type="textbox" disabled />
                <img onClick={()=> setVerify(makeid(5))} src={Reload}/>
            </Verify>
            <VerifyInp>
                <span>Kod Girişi</span>
                <input value={verifyInput} maxlength="5" required onChange={e => _handleInput(e.target.value)} type="textbox" />
            </VerifyInp>
            <button type="submit">Gönder</button>{hata === null?"":hata?<Hata color="red">Doğrulama kodunu kontrol edin.</Hata>:<Hata color="green">Mailiniz iletildi.</Hata>}
            </form>
        </MailInputs>
    </MailWrap>
} 